@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
  
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

   
}

.react-flow__connectionline {
  z-index: 0 !important;;
}

.react-flow__node-commentNode {
  z-index: -10 !important;
}

.react-colorful__saturation-pointer {
  border: 2px solid white !important;
  height: 10px !important;
  width: 10px !important;
}

.react-colorful__hue-pointer {
  border: 2px solid white !important;
  height: 25px !important;
  width: 10px !important;
  border-radius: 2px !important;
}


.webkit-fillable{
  max-height: -webkit-fill-available;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}